import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function prefetchRegion(queryClient, api, slug) {
    return queryClient.prefetchQuery({
        queryKey: ['region', slug],
        queryFn: ({ queryKey: [, slugParam], signal }) =>
            api.locations.regions(
                {
                    uri: slugParam,
                },
                null,
                null,
                { signal },
            ),
    });
}

export function fetchRegion(queryClient, api, slug) {
    return queryClient.fetchQuery({
        queryKey: ['region', slug],
        queryFn: ({ queryKey: [, slugParam], signal }) =>
            api.locations.regions(
                {
                    uri: slugParam,
                },
                null,
                null,
                { signal },
            ),
    });
}

export default function useRegion(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: region, ...queryResult } = useQuery({
        queryKey: ['region', slug],
        queryFn: ({ queryKey: [, slugParam], signal }) =>
            api.locations.regions(
                {
                    uri: slugParam,
                },
                null,
                null,
                { signal },
            ),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    return {
        region,
        ...queryResult,
    };
}
